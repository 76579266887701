import React, {
  FunctionComponent,
  ReactElement,
  memo,
  useCallback,
  useState
} from 'react';
import { Form, Button, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import {
  formItemLayout,
  tailFormItemLayout,
  fileStyle,
  titleStyle,
  subTitleStyle
} from './CheckSigningsForm.style';
import ky from 'ky';
import Success from '../statuses/Success';
import Error from '../statuses/Error';
import { hashFile } from '../../utils/hashFile';
import { Status } from '../../types/Status';
import { StatusValues } from '../../constants/StatusValues';
import { handleError } from '../../utils/handleError';

const CheckSigningsForm: FunctionComponent = (): ReactElement => {
  const [form] = Form.useForm();
  const { Dragger } = Upload;

  const [signingsNumber, setSigningsNumber] = useState<number>(0);
  const [status, setStatus] = useState<Status>({
    value: StatusValues.processing,
    error: ''
  });

  const handleFinish = useCallback(() => {
    const file = form.getFieldValue('file');
    const hashedFile = hashFile({
      fileContent: file.fileContent,
      lastModified: file.lastModified
    });
    ky.post(`${process.env.API_ENDPOINT}/check-signings`, {
      json: {
        hashedFile
      }
    }).then(async response => {
      const {signingsNumber} = await response.json();
      setSigningsNumber(signingsNumber)
      setStatus({
        value: StatusValues.success,
        error: ''
      });
    }).catch(async err => {
      setStatus({
        value: StatusValues.error,
        error: handleError(await err.response.json())
      });
    });
  }, []);

  if (status.value === StatusValues.success) {
    return <Success title={`Number of signings: ${signingsNumber}`} />;
  } else if (status.value === StatusValues.error) {
    return <Error title={status.error} />;
  } 

  return (
    <>
      <h1 style={titleStyle}>BDMS</h1>
      <h3 style={subTitleStyle}>Please provide file to check signings number</h3>
      <Form
        {...formItemLayout}
        form={form}
        onFinish={handleFinish}
        name="dataForm"
        scrollToFirstError
      >
        <Form.Item
          label="File"
          rules={[{ required: true, message: 'Please upload file' }]}
          shouldUpdate
        >
          {() => {
            return form.getFieldValue('file') ? (
              <p style={fileStyle}>{form.getFieldValue('file').name}</p>
            ) : (
              <Form.Item
                name="hashData"
                rules={[{ required: true, message: 'Please upload file' }]}
                shouldUpdate
              >
                <Dragger
                  name="file"
                  beforeUpload={file => {
                    const reader = new FileReader();
                    reader.onload = event => {
                      form.setFieldsValue({
                        file: {
                          name: file.name,
                          fileContent: event.target.result,
                          lastModified: file.lastModified,
                        }
                      });
                    };
                    reader.onerror = error => {
                      throw error;
                    };
                    reader.readAsArrayBuffer(file);
                    return false;
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
              </Form.Item>
            );
          }}
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            Verify file
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default memo(CheckSigningsForm);
