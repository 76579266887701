import React, {
  FunctionComponent,
  ReactElement,
  memo,
} from 'react';
import { Result, Button } from 'antd';
import { useHistory } from 'react-router';

interface Warning {
  title: string;
}

const Warning: FunctionComponent<Warning> = ({ title }): ReactElement => {
  const history = useHistory();

  return (
    <>
      <Result
        status="warning"
        title={title}
        extra={
          <Button type="link" onClick={() => history.push('/')}>Homepage</Button>
        }
      />
    </>
  )
}

export default memo(Warning);
