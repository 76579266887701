import React, {
  FunctionComponent,
  ReactElement,
  memo,
  useCallback,
  useState
} from 'react';
import { Form, Input, Button, Upload } from 'antd';
import ky from 'ky';
import { useHistory } from 'react-router-dom';
import { InboxOutlined } from '@ant-design/icons';
import {
  formItemLayout,
  tailFormItemLayout,
  titleStyle,
  fileStyle,
  subTitleStyle
} from './FileSigningForm.style';
import Error from '../statuses/Error';
import { hashData } from '../../utils/hashData';
import { FileSigningFormValues } from '../../types/FileSigningFormValues';
import { Status } from '../../types/Status';
import { StatusValues } from '../../constants/StatusValues';
import { handleError } from '../../utils/handleError';

const FileSigningForm: FunctionComponent = (): ReactElement => {
  const [form] = Form.useForm();
  const { Dragger } = Upload;

  const history = useHistory();
  const [status, setStatus] = useState<Status>({
    value: StatusValues.processing,
    error: ''
  });
  
  const handleFinish = useCallback((result: FileSigningFormValues) => {
    const file = form.getFieldValue('file')
    const hashedData = hashData({
      fileContent: file.fileContent,
      lastModified: file.lastModified
    },
    {
      name: result.name,
      surname: result.surname,
      email: result.email
    })
    ky.post(`${process.env.API_ENDPOINT}/register-user`, {
      json: {
        name: result.name,
        surname: result.surname,
        email: result.email,
        filename: file.name
      }
    }).then(() => {
      history.push('/activation-code', {
        email: result.email,
        hashedData
      });
    }).catch(async err => {
      console.error(err);
      setStatus({
        value: StatusValues.error,
        error: handleError(await err.response.json())
      });
    });
  }, []);

  if (status.value === StatusValues.error) {
    return <Error title={status.error} />
  }
  
  return (
    <>
      <h1 style={titleStyle}>BDMS</h1>
      <h3 style={subTitleStyle}>Please provide your information</h3>
      <Form
        {...formItemLayout}
        form={form}
        onFinish={handleFinish}
        name="dataForm"
        scrollToFirstError
      >
        <Form.Item
          name="name"
          label={'Name'}
          rules={[
            {
              required: true,
              message: 'Please input your name!',
              whitespace: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="surname"
          label={'Surname'}
          rules={[
            {
              required: true,
              message: 'Please input your surname!',
              whitespace: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!'
            },
            {
              required: true,
              message: 'Please input your E-mail!'
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="emailConfirm"
          label="Confirm email"
          dependencies={['email']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your email!'
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('email') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  'The two email that you entered do not match!'
                );
              }
            })
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="File"
          rules={[{ required: true, message: 'Please upload file' }]}
          shouldUpdate
        >
          {() => {
            return form.getFieldValue('file') ? (
              <p style={fileStyle}>{form.getFieldValue('file').name}</p>
            ) : (
              <Form.Item
                name="hashData"
                rules={[{ required: true, message: 'Please upload file' }]}
                shouldUpdate
              >
                <Dragger
                  name="file"
                  beforeUpload={file => {
                    const reader = new FileReader();
                    reader.onload = event => {
                      form.setFieldsValue({
                        file: {
                          name: file.name,
                          fileContent: event.target.result,
                          lastModified: file.lastModified,
                        }
                      });
                    };
                    reader.onerror = error => {
                      throw error;
                    };
                    reader.readAsArrayBuffer(file);
                    return false;
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
              </Form.Item>
            );
          }}
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            Sign document
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default memo(FileSigningForm);
