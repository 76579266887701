import React, {
  FunctionComponent,
  ReactElement,
  memo,
} from 'react';
import { Result, Button } from 'antd';
import { useHistory } from 'react-router';

interface Error {
  title: string;
}

const Error: FunctionComponent<Error> = ({ title }): ReactElement => {
  const history = useHistory();

  return (
    <>
      <Result
        status="error"
        title={title}
        extra={
          <Button type="link" onClick={() => history.push('/')}>Homepage</Button>
        }
      />
    </>
  )
};

export default memo(Error);
