import React, {
  FunctionComponent,
  ReactElement,
  memo,
  useCallback,
  useState
} from 'react';
import { Form, Input, Button, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import {
  formItemLayout,
  tailFormItemLayout,
  titleStyle,
  subTitleStyle,
  fileStyle
} from './VerificationForm.style';
import ky from 'ky';
import { hashData } from '../../utils/hashData';
import { VerificationFormValues } from '../../types/VerificationFormValues';
import Success from '../statuses/Success';
import Warning from '../statuses/Warning';
import Error from '../statuses/Error';
import { VerificationStatusValues } from '../../constants/VerificationStatus';
import { Status } from '../../types/Status';
import { handleError } from '../../utils/handleError';

const FileSigningForm: FunctionComponent = (): ReactElement => {
  const [form] = Form.useForm();
  const { Dragger } = Upload;

  const [status, setStatus] = useState<Status>({
    value: VerificationStatusValues.processing,
    error: ''
  });

  const handleFinish = useCallback((result: VerificationFormValues) => {
    const file = form.getFieldValue('file')
    const hashedData = hashData({
      fileContent: file.fileContent,
      lastModified: file.lastModified
    },
    {
      name: result.name,
      surname: result.surname,
      email: result.email
    })
    ky.post(`${process.env.API_ENDPOINT}/verify-file`, {
      json: {
        name: result.name,
        surname: result.surname,
        email: result.email,
        hashedData
      }
    }).then(async response => {
      const {status} = await response.json();
      setStatus({
        value: status,
        error: ''
      });
    }).catch(async err => {
      console.error(err);
      setStatus({
        value: VerificationStatusValues.error,
        error: handleError(await err.response.json())
      });
    });
  }, []);

  if (status.value === VerificationStatusValues.signed) {
    return <Success title={'File was singed'} />;
  } else if (status.value === VerificationStatusValues.multipleSignatures) {
    return <Warning title={'File was signed multiple times'} />;
  } else if (status.value === VerificationStatusValues.notSigned) {
    return <Error title={'File was not signed'} />;
  } else if (status.value === VerificationStatusValues.error) {
    return <Error title={'Server error'} />;
  }
  
  return (
    <>
      <h1 style={titleStyle}>BDMS</h1>
      <h3 style={subTitleStyle}>Please provide information of the person who was supposed to sign document</h3>
      <Form
        {...formItemLayout}
        form={form}
        onFinish={handleFinish}
        name="dataForm"
        scrollToFirstError
      >
        <Form.Item
          name="name"
          label={'Name'}
          rules={[
            {
              required: true,
              message: 'Please input your name!',
              whitespace: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="surname"
          label={'Surname'}
          rules={[
            {
              required: true,
              message: 'Please input your surname!',
              whitespace: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!'
            },
            {
              required: true,
              message: 'Please input your E-mail!'
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="File"
          rules={[{ required: true, message: 'Please upload file' }]}
          shouldUpdate
        >
          {() => {
            return form.getFieldValue('file') ? (
              <p style={fileStyle}>{form.getFieldValue('file').name}</p>
            ) : (
              <Form.Item
                name="hashData"
                rules={[{ required: true, message: 'Please upload file' }]}
                shouldUpdate
              >
                <Dragger
                  name="file"
                  beforeUpload={file => {
                    const reader = new FileReader();
                    reader.onload = event => {
                      form.setFieldsValue({
                        file: {
                          name: file.name,
                          fileContent: event.target.result,
                          lastModified: file.lastModified,
                        }
                      });
                    };
                    reader.onerror = error => {
                      throw error;
                    };
                    reader.readAsArrayBuffer(file);
                    return false;
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
              </Form.Item>
            );
          }}
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            Verify file
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default memo(FileSigningForm);
