import React, { FunctionComponent, memo, ReactElement } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import FileSigningForm from './components/fileSigningForm/FileSigningForm';
import ActivationCodeForm from './components/activationCodeForm/ActivationCodeForm';
import VerificationForm from './components/verificationForm/VerificationForm';
import CheckSigningsForm from './components/checkSigningsForm/CheckSigningsForm';
import StartPage from './components/startPage/StartPage';

const App: FunctionComponent = (): ReactElement => {

  return (
    <BrowserRouter>
      <Switch>
        <Route path={'/sign-file'} component={FileSigningForm} />
        <Route path={'/activation-code'} component={ActivationCodeForm} />
        <Route path={'/verify-file'} component={VerificationForm} />
        <Route path={'/check-signings'} component={CheckSigningsForm} />
        <Route path={'/'} component={StartPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default memo(App);
