export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  }
};

export const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 8,
      offset: 8,
    },
  }
};

export const titleStyle = {
  marginTop: '10vw',
  textAlign: 'center'
};

export const subTitleStyle = {
  marginBottom: '2vw',
  textAlign: 'center'
};

export const fileStyle = {
  margin: 0
};
