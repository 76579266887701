import sjcl from 'sjcl';
import { stringToArrayBuffer } from './stringToArrayBuffer';
import { mergeArrayBuffers } from './mergeArrayBuffers';
import { FileData } from '../types/FileData';
import { parseArrayBuffer } from './parseArrayBuffer';

interface UserData {
  name: string;
  surname: string;
  email: string;
}

export const hashData = (fileData: FileData, userData: UserData) => {
  const lastModifiedAsArrayBuffer = stringToArrayBuffer(fileData.lastModified);
  const arrayBufferToHash = mergeArrayBuffers(
    fileData.fileContent,
    lastModifiedAsArrayBuffer
  );
  const arrayBufferOutput = sjcl.hash.sha256.hash(
    parseArrayBuffer.toBits(arrayBufferToHash)
  );
  const arrayBufferHash = sjcl.codec.hex.fromBits(arrayBufferOutput);
  const stringToHash = Object.values(userData).join('');
  const stringOutput = sjcl.hash.sha256.hash(stringToHash);
  const stringHash = sjcl.codec.hex.fromBits(stringOutput);
  return `0x${arrayBufferHash.concat(stringHash)}`;
};
