export const Container = {
  width: '50%',
  margin: '20vw auto',
  textAlign: 'center'
}

export const ButtonsContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '5vw'
};
