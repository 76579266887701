import React, { FunctionComponent, ReactElement } from 'react';
import { Button } from 'antd';
import { Container, ButtonsContainer } from './StartPage.style';
import { useHistory } from 'react-router-dom';

const StartPage: FunctionComponent = (): ReactElement => {
  const history = useHistory();

  return (
    <div style={Container}>
      <h1>BDMS</h1>
      <div style={ButtonsContainer}>
        <Button type="primary" value="large" onClick={() => history.push('/sign-file')}>Sign document</Button>
        <Button value="large" onClick={() => history.push('/check-signings')}>Check signings number</Button>
        <Button value="large" onClick={() => history.push('/verify-file')}>Verify document</Button>
      </div>
    </div>

  )
}

export default StartPage;
