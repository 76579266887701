import React, {
  FunctionComponent,
  ReactElement,
  memo,
  useCallback,
  useState
} from 'react';
import { Form, Input, Button } from 'antd';
import {
  formItemLayout,
  tailFormItemLayout,
  titleStyle,
} from './ActivationCodeForm.style';
import ky from 'ky';
import { useLocation } from 'react-router-dom';
import { ActivationCodeFormValues } from '../../types/ActivationCodeFormValues';
import Success from '../statuses/Success';
import Error from '../statuses/Error';
import { Status } from '../../types/Status';
import { StatusValues } from '../../constants/StatusValues';
import { handleError } from '../../utils/handleError';

interface LocationState {
  email: string;
  hashedData: string;
}

const ActivationCodeForm: FunctionComponent = (): ReactElement => {
  const [form] = Form.useForm();

  const location = useLocation();
  const [status, setStatus] = useState<Status>({
    value: StatusValues.processing,
    error: ''
  });

  const handleFinish = useCallback((result: ActivationCodeFormValues) => {
    const state = location.state as LocationState;

    ky.post(`${process.env.API_ENDPOINT}/sign-in-file`, {
      json: {
        email: state.email,
        hashedData: state.hashedData,
        activationCode: result.code,
      }
    }).then(() => {
      setStatus({
        value: StatusValues.success,
        error: ''
      });
    }).catch(async err => {
      setStatus({
        value: StatusValues.error,
        error: handleError(await err.response.json())
      });
    });
  }, []);

  if (status.value === StatusValues.success) {
    return <Success title={'File has been signed'} />;
  } else if (status.value === StatusValues.error) {
    return <Error title={status.error} />;
  } 
  
  return (
    <>
      <h1 style={titleStyle}>BDMS</h1>
      <Form
        {...formItemLayout}
        form={form}
        onFinish={handleFinish}
        name="codeForm"
        scrollToFirstError
      >
        <Form.Item
          name="code"
          label={'Code from email'}
          rules={[
            {
              required: true,
              message: 'Please input code from the email!',
              whitespace: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            Confirm signing
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default memo(ActivationCodeForm);
