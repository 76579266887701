import sjcl from 'sjcl';
import { stringToArrayBuffer } from './stringToArrayBuffer';
import { mergeArrayBuffers } from './mergeArrayBuffers';
import { FileData } from '../types/FileData';
import { parseArrayBuffer } from './parseArrayBuffer';

export const hashFile = (fileData: FileData) => {
  const lastModifiedAsArrayBuffer = stringToArrayBuffer(fileData.lastModified);
  const arrayBufferToHash = mergeArrayBuffers(
    fileData.fileContent,
    lastModifiedAsArrayBuffer
  );
  const arrayBufferOutput = sjcl.hash.sha256.hash(
    parseArrayBuffer.toBits(arrayBufferToHash)
  );
  const arrayBufferHash = sjcl.codec.hex.fromBits(arrayBufferOutput);

  return `0x${arrayBufferHash}`;
};
